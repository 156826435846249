<template>
  <div class="px-2 px-md-5 grey lighten-5">
    <page-header :title="title">
      <template #toolbar-options>
        <slot name="toolbar-options" />
      </template>
    </page-header>
    <v-layout column class="container-page ml-md-4 pt-0 px-1 overflow-x-hidden overflow-y-auto"
              fill-height style="position: relative; height: calc(100vh - 95px);
    ">
      <slot class="fill-height" />
    </v-layout>
  </div>
</template>

<script>
import PageHeader from "@/components/headers/page-header";
export default {
  props: ['title'],
  components: {PageHeader}
}
</script>

<style scoped>

</style>
