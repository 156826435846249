<template>
  <v-menu class="profile-menu-dropdown" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="my-0 pa-0 px-1 pb-2 mb-1 pt-2" height="28" text v-on="on" v-bind="attrs" shaped :ripple="false">
        <v-layout align-center>
          <v-icon small color="grey darken-2" class="mr-1" style="margin-bottom: 2px">mdi-account-circle</v-icon>
          <v-layout column justify-start align-start>
            <small style="font-size: 11px" class="text-capitalize">{{ (userName.split(' ')[0] + ' ' + (userName.split(' ')[1] || '')) | capitalize }}</small>
          </v-layout>
          <v-icon small class="ml-1 mb-1">mdi-menu-down</v-icon>
        </v-layout>
      </v-btn>
    </template>

    <v-list>
      <v-list-item class="ma-0 pa-0">
        <v-layout column justify-start>
          <v-btn class="d-flex justify-start mb-2" text link elevation="0" color="grey darken-2" tile small @click="$router.push({name: 'Profile'})">
            <v-icon size="16">mdi-account</v-icon>
            <span class="caption text-capitalize ml-2">Perfil</span>
          </v-btn>
          <v-btn class="d-flex justify-start" text link elevation="0" color="grey darken-2" tile small @click="onLogout">
            <v-icon size="16">mdi-export</v-icon>
            <span class="caption text-capitalize ml-2">Sair</span>
          </v-btn>
        </v-layout>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: ['userName'],
  methods: {
    onLogout() {
      this.$router.push({name: 'Login'})
      this.$store.dispatch('auth/revokeAthentication')
    }
  }
}
</script>

<style scoped>

</style>
