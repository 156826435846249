<template>
  <v-toolbar elevation="0" color="transparent">
    <v-layout column>
      <v-toolbar-title class="headline font-weight-regular">
        <v-layout align-center>
          {{ title }}
          <v-spacer></v-spacer>
          <v-layout justify-end align-center class="pr-4 pb-1 flex-grow-1">
            <slot name="toolbar-options" />
          </v-layout>
          <v-btn v-if="$vuetify.breakpoint.mobile" class="d-block d-lg-none" icon @click="$store.dispatch('toggleMenu')">
            <v-icon style="margin-bottom: 2px">mdi-menu</v-icon>
          </v-btn>
          <profile-menu-dropdown v-else :user-name="$store.state.auth.data.name" />
        </v-layout>
      </v-toolbar-title>
      <v-divider></v-divider>
    </v-layout>
  </v-toolbar>
</template>

<script>
import ProfileMenuDropdown from "@/components/menus/profile-menu-dropdown";
export default {
  components: {ProfileMenuDropdown},
  props: ['title']
}
</script>

<style lang="scss">
.v-toolbar__content {
  padding-right: 0!important;
}
@media(max-width: 959px) {
  .v-toolbar__content {
    padding: 0!important;
  }
}
</style>
